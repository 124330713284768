/*
 * @Desc: This is desc
 * @Version: v0.0.1
 * @Author: Chenxue
 * @Date: 2021-09-15 19:34:18
 */

const Host = "https://nft-stats-0918.easyar.com/";

const config = {
  //所有的Host接口全部设置请求头：Authorization
  commonHeader: {
    "Access-Control-Allow-Credentials": "true",
  },
  commonBodyHeader: {
    "Content-Type": "application/json;charset=UTF-8",
  },
  commonFormHeader: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  host: Host,
  list: Host + "portal/stats/",
  viewList: Host + "portal/stats/luckydoge/logs",
  fromList: Host + "portal/stats/luckydoge/channels",
};

export default config;
