/*
 * @Desc: This is desc
 * @Version: v0.0.1
 * @Author: Chenxue
 * @Date: 2021-09-15 19:19:20
 */
import React from "react";
import "../style/App.css";
import common from "../common";
import config from "../config/config";
import { Table, ConfigProvider, Select, Button, DatePicker } from "antd";

import zh_CN from "antd/lib/locale-provider/zh_CN";
import "moment/locale/zh-cn";

const { Option } = Select;
const { RangePicker } = DatePicker;

class App extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      list: [],
      from: "",
      total: 0,
      currentPage: 1,
      pageSize: 10,
      fromList: [],
      startTime: null,
      endTime: null,
      type: "luckydoge",
    };
  }

  componentDidMount() {
    if (window.location.pathname.split("/").pop()) {
      this.setState({ type: window.location.pathname.split("/").pop() }, () => {
        this.getFromList();
        this.getList();
      });
    } else {
      this.setState({ type: "luckydoge" }, () => {
        this.getFromList();
        this.getList();
      });
    }
  }

  changeType() {
    if (this.state.type === "luckydoge") {
      this.setState({ type: "badassapeguild" }, () => {
        this.getFromList();
        this.getList();
      });
    } else {
      this.setState({ type: "luckydoge" }, () => {
        this.getFromList();
        this.getList();
      });
    }
  }

  getFromList() {
    common
      .requestCommon("get", config.list + this.state.type + "/channels")
      .then((msg) => {
        this.setState({
          fromList: msg["data"] ? msg["data"] : [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getList() {
    common
      .requestCommon("get", config.list + this.state.type + "/logs", {
        channelId: this.state.from,
        startTime: this.state.startTime,
        endTime: this.state.endTime,
        page: this.state.currentPage,
        size: this.state.pageSize,
      })
      .then((msg) => {
        this.setState({
          total: msg["data"]["count"],
          list: msg["data"]["items"],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  onChangeTime(value, dateString) {
    if (value) {
      this.setState({
        startTime: Date.parse(new Date(dateString[0])) / 1000,
        endTime: Date.parse(new Date(dateString[1])) / 1000,
      });
    } else {
      this.setState({
        startTime: null,
        endTime: null,
      });
    }
    // console.log(value, dateString);
  }

  getDayTime(timeTxt) {
    if (!timeTxt) return "";
    let ms = parseInt(timeTxt);
    if (ms < 10000000000) {
      ms = ms * 1000;
    }

    let oDate = new Date(ms),
      oYear = oDate.getFullYear(),
      oMonth = oDate.getMonth() + 1,
      oDay = oDate.getDate(),
      oHour = oDate.getHours(),
      oMin = oDate.getMinutes(),
      oSec = oDate.getSeconds();
    oMonth = oMonth > 9 ? oMonth : "0" + oMonth;
    oDay = oDay > 9 ? oDay : "0" + oDay;
    oHour = oHour > 9 ? oHour : "0" + oHour;
    oMin = oMin > 9 ? oMin : "0" + oMin;
    oSec = oSec > 9 ? oSec : "0" + oSec;
    let _time =
      oYear + "/" + oMonth + "/" + oDay + " " + oHour + ":" + oMin + ":" + oSec;
    return _time;
  }

  render() {
    this.columns = [
      {
        title: "transaction_id",
        dataIndex: "txHash",
        key: "txHash",
        align: "center",
      },
      {
        title: "渠道来源",
        dataIndex: "channel",
        key: "channel",
        align: "center",
      },
      {
        title: "购买数量",
        dataIndex: "quantity",
        key: "quantity",
        align: "center",
      },
      {
        title: "金额/eth",
        dataIndex: "price",
        key: "price",
        align: "center",
      },
      {
        title: "下单时间",
        dataIndex: "createTime",
        key: "createTime",
        align: "center",
        render: (time) => {
          return this.getDayTime(time);
        },
      },
    ];

    return (
      <div id="App">
        <div className="tab">
          {this.state.type === "luckydoge" ? "LUCKY DOGE " : "BadassApe Guild "}
          —— MINT统计
        </div>
        <div className="waitingBox">
          <div style={{ marginBottom: "20px" }}>
            <Button
              type="primary"
              style={{ marginRight: "15px" }}
              onClick={() => {
                this.changeType();
              }}
            >
              切换至
              {this.state.type === "luckydoge"
                ? "BadassApe Guild "
                : "LUCKY DOGE "}
            </Button>
            <Select
              showSearch
              optionFilterProp="children"
              defaultValue={undefined}
              style={{ width: 220 }}
              onChange={(value) => {
                this.setState({ from: value });
              }}
              placeholder={"请选择来源渠道"}
              allowClear
            >
              {this.state.fromList.map((item, index) => (
                <Option value={item["id"]} key={index}>
                  {item["name"]}
                </Option>
              ))}
            </Select>
            <ConfigProvider locale={zh_CN}>
              <RangePicker
                style={{
                  marginLeft: "10px",
                  width: "330px",
                }}
                defaultValue={null}
                showTime={{ format: "HH:mm:ss" }}
                format="YYYY-MM-DD HH:mm:ss"
                placeholder={["开始时间", "结束时间"]}
                onChange={(value, dateString) => {
                  this.onChangeTime(value, dateString);
                }}
                locale={zh_CN}
              />
            </ConfigProvider>
            <Button
              type="primary"
              style={{ marginLeft: "15px" }}
              onClick={() => {
                this.getList();
              }}
            >
              查询
            </Button>
          </div>
          <ConfigProvider locale={zh_CN}>
            <Table
              rowKey="txHash"
              bordered={true}
              columns={this.columns}
              dataSource={this.state.list}
              style={{ margin: "50px 0" }}
              pagination={{
                showQuickJumper: true,
                showSizeChanger: true,
                total: this.state.total,
                current: this.state.currentPage,
                pageSize: this.state.pageSize,
                onChange: (page, pageSize) => {
                  this.setState(
                    { currentPage: page, pageSize: pageSize },
                    () => {
                      this.getList();
                    }
                  );
                },
              }}
            />
          </ConfigProvider>
        </div>
      </div>
    );
  }
}

export default App;
