/*
 * @Desc: This is desc
 * @Version: v0.0.1
 * @Author: Chenxue
 * @Date: 2021-09-14 15:14:23
 */
import React from "react";
import ReactDOM from "react-dom";
import "./style/index.css";
import App from "./page/App";
import "antd/dist/antd.css";

import "moment/locale/zh-cn";

// import reportWebVitals from "./reportWebVitals";
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
