/*
 * 此为全局公用对象
 * 不同的加密请求对象等
 * 包含所有请求
 * 权限检测
 * 公共事件处理
 * 时间格式转化
 * 错误请求码所对应的对象等
 * */
import axios from "axios";
import qs from "qs";
import config from "./config/config";

const sha256 = window.sha256;

axios.defaults.withCredentials = true;

const common = {
  //请求数据完全放入params中
  requestCommon(type, url, params, headers) {
    let options = {
      method: type,
      url: url,
      params: params,
    };
    if (headers) {
      options.headers = headers;
    }
    return new Promise(function (resolve, reject) {
      axios(options)
        .then((msg) => {
          resolve(msg.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  //请求数据完全放入body中
  requesBodyCommon(type, url, data, headers, isQs, timeout) {
    let options = {
      method: type,
      url: url,
      data: data,
    };

    if (headers) {
      options.headers = headers;
    }

    if (isQs) {
      options.data = qs.stringify(data);
    }

    if (timeout) {
      options.timeout = timeout;
    }

    return new Promise(function (resolve, reject) {
      axios(options)
        .then((msg) => {
          resolve(msg.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  //请求数据完全放入body中,无cookie
  requesBodyCommonNoCookie(type, url, data, headers, isQs) {
    let options = {
      method: type,
      url: url,
      data: data,
      withCredentials: false,
    };

    if (headers) {
      options.headers = headers;
    }

    if (isQs) {
      options.data = qs.stringify(data);
    }

    return new Promise(function (resolve, reject) {
      axios(options)
        .then((msg) => {
          resolve(msg.data);
        })
        .catch((err) => {
          reject(err.response);
        });
    });
  },

  ajax(options) {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest();
      var str = "";
      for (var key in options.data) {
        str += "&" + key + "=" + options.data[key];
      }
      str = str.slice(1);
      if (options.type == "get") {
        var url = options.url + "?" + str;
        xhr.open("get", url);
        xhr.send();
      } else if (options.type == "post") {
        xhr.open("post", options.url);
        xhr.setRequestHeader(
          "content-type",
          "application/x-www-form-urlencoded"
        );
        xhr.send(str);
      }
      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          var d = xhr.responseText;
          resolve(JSON.parse(d));
        } else if (xhr.status != 200) {
          reject(xhr);
        }
      };
    });
  },
};

export default common;
